import React from "react";
import Card from "react-bootstrap/Card";
// import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            At <span className="purple">Brand Amplify </span>
            we are a group of <span className="purple"> Experienced Developers</span>
            <br />
            who are working as freelancers to bring your vision into existence
            {/* I am currently employed as a software developer at MantraLabs. */}
            
            .Based on the services that we offer,we aim to provide you ease of scaling up your business online without you having to worry about the tech part.
            {/* I have completed Integrated MSc (IMSc) in Maths and Computing at BIT
            Mesra. */}
            
            . Our goal is to understand client requirements and provide them high quality efficient products
            . We also ​strictly value our clients’ confidential information and protection of ​intellectual property
            . We have delivered quite a few products to our clients which are in the content delivery and live streaming domain
             
            . Not only do we provide high quality products but we also provide analytics tools so that the client gets insight into users' screen time patterns, habits, preferences so that the user experience is personalized and greatly improved
            . If you have some vision about scaling up your product/business online and want to make it a reality, wait no more. Contact us at- <a href="mailto:info@brandamplify.in?subject=Greetings&body=Hi, I came across brand aplify and all the work it does, I would like to talk about a product that i want to build and it's feasibility.">Send Email</a>
            
            <br/>
            <br/>
            WAIT NO MORE. MAKE THIS YEAR WORTH HAPPENING FOR YOUR VENTURE.COME,BUILD YOUR FUTURE WITH US.
            <br/>

          </p>
          <ul>
            {/* <li className="about-activity">
              <ImPointRight /> Physics
            </li>
            <li className="about-activity">
              <ImPointRight /> Mathematics
            </li>
            <li className="about-activity">
              <ImPointRight /> AI and ML
            </li>
            <li className="about-activity">
              <ImPointRight /> Martial arts
            </li>
            <li className="about-activity">
              <ImPointRight /> Sports (cricket)
            </li> */}
          </ul>
          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">ChandraSekhar</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
