import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
// import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">We are</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> we use
        </h1>
        <Toolstack />

        {/* <Github /> */}
        <Row className="mt-5 align-items-center">
        <Col md={2}></Col>
        <Col md={3} className="text-center">
          <img src="/developer.jpg" alt="Chandra Sekhar Sahu" className="img-fluid" />
          <p className="mt-2">Chandra Sekhar Sahoo      Co-Founder,Full stack developer</p>
        </Col>
        <Col md={2}></Col>
        <Col md={3} className="text-center">
          <img src="sarthak.jpg" alt="Sarthak Parija" className="img-fluid" />
          <p className="mt-2">Sarthak Parija            Co-Founder,Data Analytics Consultant</p>
        </Col>
        <Col md={2}></Col>
      </Row>
      </Container>
    </Container>

  );
}

export default About;
