import React from "react";
import './Blog.css'; // Make sure the path to your CSS file is correct
import { Container ,Button} from "react-bootstrap";
import Particle from "../Particle";

const testimonials = [
  { name: "Deepak Panda", profession: "UI/UX Designer", testimony: "“I was fascinated by art and painting since ​my childhood. I always had a dream to ​showcase my work and make it reach to a ​massive audience online. Sarthak and ​Chandra have been the artists who have ​made my dreams come true ”",imageUrl:"/images/testimonial_1.png" },
  { name: "Siddharth Das", profession: "Gamer", testimony: "“Sarthak and Chandra have been really ​instrumental in their approach so far, I ​wish them all the best for their future ​endeavors. ”" ,imageUrl:"/images/testimonial_2.png"},
  { name: "Rajesh Birumann", profession: "Lead Java Developer", testimony: "“Sarthak and Chandra have been superb ​and awesome in their approach so far. ​Cant wait anymore to get my own ​personalized branding to take place.”" ,imageUrl:"/images/testimonial_3.png"},
  // ... more testimonials
];

function Blog() {

    const googleFormUrl = "https://docs.google.com/forms/d/1YfTytw9-FbcvcSzBF3ugFkBl-lbf1HcqIlACzQSFtY4/edit?pli=1";
    const handleConsultationClick = () => {
      window.open(googleFormUrl, "_blank");
    };

    return (
        <section>
        <Container fluid className="home-section" id="home">
            <Particle/>
            <Container>
                <div className="testimonials-container">
                    <div className="testimonials-header">
                        <h2>What Our Clients Say</h2>
                        <p>Dive into these testimonials to explore the profound connections and success stories that resonate beneath the surface of our products/services.</p>
                    </div>
                    <div className="testimonials-grid">
                        {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-card">
                            <div className="testimonial-top">
                            <img src={testimonial.imageUrl} alt={testimonial.name} className="testimonial-image" />
                            <div className="testimonial-info">
                                <p>{testimonial.name}</p>
                                <p>{testimonial.profession}</p>
                            </div>
                            </div>
                            <p className="testimonial-description">{testimonial.testimony}</p>
                        </div>
                        ))}
                    </div>
                </div>
            </Container>
        </Container>
        <Button variant="primary" style={{ 
                        marginTop: '30px',
                        marginBottom: '20px', 
                        fontSize: '1.2rem', // Increase font size
                        padding: '10px 20px' // Increase padding
                    }}
                    onClick={handleConsultationClick}> BOOK A FREE CONSULTATION </Button>
        </section>
        
        
    );
}

export default Blog;
