import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; // Import Button here
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  const googleFormUrl = "https://docs.google.com/forms/d/1YfTytw9-FbcvcSzBF3ugFkBl-lbf1HcqIlACzQSFtY4/edit?pli=1";

  // Handler function to open the form
  const handleConsultationClick = () => {
    window.open(googleFormUrl, "_blank");
  };
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                We Are
                <strong className="main-name"> A GROUP OF PASSIONATE FREELANCERS/TECH PROFESSIONALS </strong>
                INTERESTED IN SCALING UP YOUR BUSINESS
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Add your button here */}
      <Button variant="primary" style={{ 
        marginBottom: '20px', 
        fontSize: '1.2rem', // Increase font size
        padding: '10px 20px' // Increase padding
      }}
      onClick={handleConsultationClick}> BOOK A FREE CONSULTATION </Button>
      <Home2 />
    </section>
  );
}

export default Home;
